<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Add New Part">
            <validation-observer ref="createPromocodeValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Code"
                      label-for="code"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="code"
                        rules="required"
                      >
                        <b-form-input
                          id="code"
                          v-model="form.code"
                          name="code"
                          placeholder="Enter code"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Type"
                      label-for="type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="type"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.type"
                          :options="typeOptions"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="status"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.status"
                          :options="statusOptions"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Discount"
                      label-for="discount"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="discount"
                        rules="required"
                      >
                        <b-form-input
                          id="discount"
                          v-model="form.discount"
                          name="discount"
                          placeholder="Enter discount"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-textarea
                          id="description"
                          v-model="form.description"
                          name="description"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="form.show_on_website"
                        class="custom-control-success"
                      >
                        Show on Website
                      </b-form-checkbox>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Create
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const discountsModule = createNamespacedHelpers('discounts')

export default {
  data() {
    return {
      loader: false,
      form: {
        code: '',
        type: null,
        status: null,
        discount: '',
        description: '',
        show_on_website: false,
        count: 0,
      },
      typeOptions: [
        { value: null, text: 'Please select type', disabled: true },
        { value: 'fixed', text: 'Fixed' },
        { value: 'percent', text: 'Percent' },
      ],
      statusOptions: [
        { value: null, text: 'Please select type', disabled: true },
        { value: 1, text: 'Enable' },
        { value: 0, text: 'Disable' },
      ],
    }
  },

  methods: {
    ...discountsModule.mapActions(['ADD_PROMOCODE']),

    onSubmit() {
      this.$refs.createPromocodeValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            this.form.show_on_website = this.form.show_on_website === true ? 1 : 0
            const resp = await this.ADD_PROMOCODE(this.form)
            if (resp) {
              this.resetForm()
              this.$nextTick(() => {
                this.$refs.createPromocodeValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Promocode created!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'Promocode has been created successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },

    resetForm() {
      this.form.code = ''
      this.form.type = null
      this.form.status = null
      this.form.discount = ''
      this.form.description = ''
      this.form.show_on_website = false
    },
  },
}
</script>
